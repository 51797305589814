@tailwind base;
@tailwind utilities;

html {
	font-size: 62.5%; /* Now 10px = 1rem! */
}

body {
	font-size: 16px; /* px fallback */
	font-size: 1.6rem; /* default font-size for document */
	line-height: 1.5; /* a nice line-height */
}

.bg-beforeAfter:after,
.bg-beforeAfter:before {
	@apply bg-yellow;
}

.line-clamp {
	display: -webkit-box;
	-webkit-line-clamp: 7;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.text-1xl {
	font-size: 1.4rem;
	line-height: 1.8rem;
}

.text-12 {
	font-size: 1.2rem;
}

.text-14 {
	font-size: 1.4rem;
}

.text-18 {
	font-size: 1.8rem;
}

.text-24 {
	font-size: 2.4rem;
}

.greyOption {
	@apply px-6 py-5 bg-grey rounded-md flex flex-row items-center my-3;
}

.optionText {
	@apply font-bold text-dark text-1xl ml-3;
}
